(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/product-header/assets/javascripts/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/lb-ui/product-header/assets/javascripts/handlebars-helpers.js');
"use strict";



const isServer = typeof exports === 'object';
const trinidad = isServer ? require('trinidad-core').core : svs.core;
const {
  resolveProductId
} = isServer ? trinidad.components.require('lb-utils', 'sport-products').api : svs.components.lbUtils.sportProducts;
const {
  getProductBrand
} = isServer ? trinidad.components.require('lb-utils', 'brand-mapping').api : svs.components.lbUtils.brandMapping.fn;
if (isServer) {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}

function registerHelpers(hbs) {
  let currId = 0;
  hbs.registerHelper('product-header-generate-id', () => {
    currId = (currId + 1) % 1000;
    return '';
  });
  hbs.registerHelper('product-header-id', () => currId);
  hbs.registerHelper('resolveBranding', branding => {
    const productId = resolveProductId(branding === null || branding === void 0 ? void 0 : branding.split('-secondary')[0]);
    let productBranding = getProductBrand(productId);
    if (branding.includes('-secondary')) {
      productBranding += '-secondary';
    }
    return productBranding;
  });
}

 })(window);